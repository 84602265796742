body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root,
.wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.wrapper {
  overflow: hidden;
}

.dark,
.dark.modal-content,
.dark input.form-control {
  background-color: rgb(33, 37, 41);
  color: #f5f5f5;
}

.dark .navbar {
  background-color: black !important;
}

.navbar-right {
  gap: 20px;
}

.light .btn.btn-primary {
  background-color: rgba(242, 79, 27, 0.8);
  border-color: rgba(242, 79, 27, 0.8);
  color: black;
}

.light .btn.btn-primary:hover {
  background-color: rgba(242, 79, 27);
  border-color: rgba(242, 79, 27);
  color: black;
}

.light .btn.btn-primary:disabled {
  background-color: rgba(242, 79, 27, 0.4);
  border-color: rgba(242, 79, 27, 0.4);
  color: black;
}

.toast-container {
  margin-bottom: 1rem;
}

.book-container {
  height: 100%;
  position: relative;
  padding-right: 0;
}

.book-table {
  height: calc(100% - 100px);
  overflow: auto;
}

.book-row {
  min-height: 150px;
  overflow: hidden;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.read {
  background-color: #d6decf;
}

.dark .read {
  color: black;
}

.tag {
  height: 24px;
  display: flex;
  align-items: center;
  background-color: #c8ccde;
  color: black;
}

.image-column {
  height: 100%;
  padding-left: 0 !important;
  width: 100px;
}

.image-column img {
  width: 100px;
}

.info-column {
  flex-grow: 1;
}

.info-column .row {
  margin: 0;
}

.info-column .title-row {
  display: flex;
  justify-content: space-between;
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  padding-right: calc(var(--bs-gutter-x) * 0.5);
}

.info-column .title-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.info-column .rating-container {
  margin-top: -2px;
}

.info-column .icon-container {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.actions-column {
  width: 125px;
  min-width: 125px;
}

.actions-column .row {
  margin: 0;
}

.author {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.tags {
  font-size: 0.8rem;
}

.tags > div {
  flex-wrap: wrap;
}

.tag .badge {
  height: 100%;
  font-size: 0.8rem;
  padding-top: 4px;
  border-radius: 0;
}

.dark .border {
  border-color: #555555 !important;
}

.book-form .buttons,
.board-form .buttons {
  margin-top: 0.5rem;
  display: flex;
  justify-content: right;
  gap: 10px;
}

.book-form .form-label {
  margin-bottom: 0;
  margin-top: 0.25rem;
}

.board-grid {
  font-size: 0.9rem;
  line-height: 1rem;
  height: 100%;
}

.read {
  background-color: #a3cfbb;
}

.board-grid .col {
  height: calc((100vh - 110px) / 5);
  padding: 0;
}

.find-book-popover .board-grid .col {
  height: calc((100vh - 200px) / 5);
}

.board-grid .col img {
  height: 100%;
}

.board-grid .col > div {
  display: flex;
  flex-direction: column;
  padding: 0;
  position: relative;
}

.board-grid .cell-content {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-grow: 10;
  position: relative;
  cursor: pointer;
  opacity: 0.9;
  height: 100%;
}

.board-grid:hover .cell-content {
  opacity: 0.6;
}

.board-grid .cell-content:hover {
  cursor: pointer;
  opacity: 1;
  border: 1px solid;
}

.board-grid .cell-content:hover img {
  margin-top: -1px;
}

.board-grid .cell-content .badge {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border-radius: 0;
}

.board-grid .image-container {
  height: 100%;
}

.board-grid .image-container .rating {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  right: 2px;
  height: 100%;
}

.category-grid {
  font-size: 0.9rem;
  line-height: 1rem;
  margin-top: 0.5rem;
}

.category-grid .col {
  min-height: 125px;
  cursor: pointer;
  position: relative;
}

.category-grid .col > div {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hard-checkbox {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.category-content {
  display: flex;
  height: 100%;
  align-items: center;
}

.category-content.no-image {
  flex-direction: column;
  justify-content: center;
}

.info {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  background: white;
  border-radius: 100px;
}

.read-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.read-container > div {
  margin-bottom: 7px;
}

.clickable-icon {
  cursor: pointer;
}

.filters {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.find-book-table {
  height: calc(100vh - 160px);
  overflow: auto;
  padding-right: 5px;
}

.dark .find-book-table .table {
  color: #f5f5f5;
}

.stats-modal .row .stats-container {
  width: 150px;
  height: 70px;
  display: flex;
  position: relative;
  justify-content: center;
}

.stats-container .count {
  font-size: 40px;
  font-weight: bold;
}

.stats-container .stat {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  left: 0;
  font-size: 0.9rem;
}

.board-grid .create-board {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.board-grid .create-board > * {
  cursor: pointer;
  opacity: 0.8;
  font-size: 1.1rem;
}

.board-grid .create-board > *:hover {
  opacity: 1;
}

.set-book-modal tr {
  cursor: pointer;
}

.set-book-modal tr.active {
  background-color: #d6decf;
}

.set-book-modal .modal-body {
  max-height: 80vh;
  overflow: auto;
}

.rating-container {
  display: flex;
}

.rating-container.horizontal {
  flex-direction: row;
}

.rating-container.vertical {
  flex-direction: column;
}

.rating-container .star-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 25px;
  height: 25px;
}

.rating-container.editable .star-container {
  cursor: pointer;
}

.rating-container .star-container .star {
  position: absolute;
  inset: 0;
  text-align: center;
}

.rating-container .star-container .left,
.rating-container .star-container .right {
  width: 50%;
  height: 100%;
  z-index: 1;
}
